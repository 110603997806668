import axios from '@core/http.ts'

export enum PaymentOptionEnum {
  'Option25_25_25_25' = '25-25-25-25',
  'Option25_25_50' = '25-25-50',
  'Option50_50' = '50-50',
  'Option25_75' = '25-75',
  'Option100' = '100'
}

export enum NoteCategoryEnum { 'ConsentAuthority' = 1, 'Client' = 2, 'Consultant' = 3, 'Miscellaneous' = 4 }

export enum TaskCostEnum { 'Hourly' = 1, 'Fixed' = 2, 'FixedNotIncluded' = 3 }

export enum ApplicationCostEnum {
  'FixedCostAndChargedHourly_FixedRate' = 1,
  'ChargedHourly_FixedRate' = 2,
  'FixedCostAndChargedHourly_ScheduleRate' = 3,
  'ChargedHourly_ScheduleRate' = 4
}

export enum AttendanceEnum { 'OnSite' = 1, 'OffSite' = 2 }

export enum ApplicationsMemberEnum { 'Pending' = 1, 'Manager' = 2, 'Member' = 3 }

export enum AttendanceBreakEnum { 'Paid' = 1, 'Unpaid' = 2 }

export enum PaymentStatusEnum { 'Sent' = 1, 'Paid' = 2 }

export enum ApplicationStatusEnum {
  'Active' = 1,
  'OnHold' = 2,
  'Finalised' = 3,
  'JobLost' = 4,
  'UnderAssessment' = 5,
  'FeeProposalSent' = 6,
  'Terminated' = 7,
  'InConstruction' = 8,
  'Build' = 9,
  'Lead' = 10,
  'LOA' = 11
}

export enum TransactionEnum { 'Payment' = 1, 'Invoice' = 2, 'Variation' = 3 }

export enum AddressEnum { 'Primary' = 1, 'Postal' = 2, 'Other' = 3 }

export enum ApplicationsClientEnum { 'Subsidiary' = 0, 'Primary' = 1 }

export enum PermissionGroupEnum {
  'AccessControl' = 1,
  'Adhoc' = 2,
  'AdhocType' = 3,
  'Application' = 4,
  'ApplicationClientType' = 5,
  'ApplicationConsultantType' = 6,
  'ApplicationPhaseType' = 8,
  'ApplicationStatusType' = 9,
  'ApplicationMemberType' = 10,
  'ApplicationType' = 11,
  'Attendance' = 12,
  'AttendanceBreakType' = 13,
  'AttendanceLocationType' = 14,
  'AttendanceType' = 15,
  'Client' = 16,
  'ClientAddressType' = 17,
  'ClientContactType' = 18,
  'Consultant' = 19,
  'ConsultantAddressType' = 20,
  'ConsultantContactType' = 21,
  'ConsultantPreferenceType' = 22,
  'ConsultantServiceType' = 23,
  'ExternalCostType' = 24,
  'Council' = 25,
  'CouncilAddressType' = 26,
  'CouncilContactType' = 27,
  'DeterminationType' = 28,
  'JobType' = 29,
  'NoteCategoryType' = 30,
  'Officer' = 31,
  'OfficerAddressType' = 32,
  'OfficerContactType' = 33,
  'OfficerPosition' = 34,
  'PaymentOption' = 35,
  'PaymentStatusType' = 36,
  'Permission' = 37,
  'Project' = 38,
  'ProjectScopeType' = 39,
  'ProjectStatusType' = 40,
  'ReferredType' = 41,
  'Role' = 42,
  'User' = 43,
  'CorrespondenceType' = 44,
  'Branch' = 45,
  'BranchAddressType' = 46,
  'BranchContactType' = 47,
  'Division' = 48,
  'TaskType' = 49,
  'TaskCostType' = 50,
  'PredefinedTask' = 51,
  'PhaseType' = 52,
  'PhaseTask' = 53,
  'Report' = 54,
  'Setting' = 55,
  'UserRate' = 56,
  'EmploymentType' = 57,
  'ApplicationClient' = 58,
  'ApplicationConsultant' = 59,
  'ApplicationMember' = 60,
  'ApplicationExternalCost' = 70,
  'ApplicationNote' = 71,
  'ApplicationPhase' = 72,
  'ApplicationTask' = 73,
  'ApplicationAccount' = 74,
  'Dashboard' = 75,
  'InhouseTask' = 76,
  'InhouseTaskGroup' = 77,
  'InhouseTaskType' = 78,
  'Absence' = 79,
  'Notification' = 80
}

export enum DeterminationEnum { 'Refused' = 1, 'Approved' = 2, 'Deferred' = 3, 'Withdrawn' = 4 }

export enum EmploymentEnum { 'FullTime' = 1, 'PartTime' = 2, 'Casual' = 3, 'Contract' = 4 }

export enum ConsultantPreferenceEnum { 'Preferred' = 1, 'Banned' = -1 }

export enum ContactEnum {
  'Phone' = 1,
  'Mobile' = 2,
  'Email' = 3,
  'OtherPhone' = 4,
  'OtherMobile' = 5,
  'OtherEmail' = 6,
  'WorkPhone' = 7,
  'Fax' = 8
}

export enum TaskEnum { 'Checklist' = 1, 'Milestone' = 2, 'Payment' = 3, 'Task' = 6 }

export enum RoleEnum {
  'SystemAdmin' = 1,
  'Executive' = 2,
  'TeamLeader' = 3,
  'ProjectDesigner' = 4,
  'ExecutiveSupport' = 5,
  'LeadProjectDesigner' = 6,
  'TownPlanner' = 7,
  'Accountant' = 8
}

export enum AdhocEnum { 'Phone' = 1, 'Meeting' = 2, 'Email' = 3 }

export enum PermissionEnum {
  'DashWorkProgress' = 'dash.work.progress',
  'DashUserStat' = 'dash.user.stat',
  'DashAccount' = 'dash.account',
  'ProjectIndex' = 'project.index',
  'ProjectSearch' = 'project.search',
  'ProjectCreate' = 'project.create',
  'ProjectView' = 'project.view',
  'ProjectUpdate' = 'project.update',
  'ProjectArchive' = 'project.archive',
  'ProjectRestore' = 'project.restore',
  'ProjectDestroy' = 'project.destroy',
  'ProjectWorkInProgress' = 'project.wip',
  'ReferTypeIndex' = 'refer.type.index',
  'ReferTypeCreate' = 'refer.type.create',
  'ReferTypeView' = 'refer.type.view',
  'ReferTypeUpdate' = 'refer.type.update',
  'ReferTypeArchive' = 'refer.type.archive',
  'ReferTypeRestore' = 'refer.type.restore',
  'ReferTypeDestroy' = 'refer.type.destroy',
  'ApplicationIndex' = 'application.index',
  'ApplicationCreate' = 'application.create',
  'ApplicationView' = 'application.view',
  'ApplicationUpdate' = 'application.update',
  'ApplicationArchive' = 'application.archive',
  'ApplicationRestore' = 'application.restore',
  'ApplicationDestroy' = 'application.destroy',
  'ApplicationSpecSync' = 'application.spec.sync',
  'ApplicationSpecView' = 'application.spec.view',
  'ApplicationClientIndex' = 'application.client.index',
  'ApplicationClientCreate' = 'application.client.create',
  'ApplicationClientUpdate' = 'application.client.update',
  'ApplicationClientArchive' = 'application.client.archive',
  'ApplicationClientRestore' = 'application.client.restore',
  'ApplicationClientDestroy' = 'application.client.destroy',
  'ApplicationConsultantIndex' = 'application.consultant.index',
  'ApplicationConsultantCreate' = 'application.consultant.create',
  'ApplicationConsultantUpdate' = 'application.consultant.update',
  'ApplicationConsultantDestroy' = 'application.consultant.destroy',
  'ApplicationMemberIndex' = 'application.member.index',
  'ApplicationMemberCreate' = 'application.member.create',
  'ApplicationMemberUpdate' = 'application.member.update',
  'ApplicationMemberDestroy' = 'application.member.destroy',
  'ApplicationExternalCostIndex' = 'application.external.cost.index',
  'ApplicationExternalCostCreate' = 'application.external.cost.create',
  'ApplicationExternalCostUpdate' = 'application.external.cost.update',
  'ApplicationExternalCostArchive' = 'application.external.cost.archive',
  'ApplicationExternalCostRestore' = 'application.external.cost.restore',
  'ApplicationExternalCostDestroy' = 'application.external.cost.destroy',
  'ApplicationNoteIndex' = 'application.note.index',
  'ApplicationNoteCreate' = 'application.note.create',
  'ApplicationNoteUpdate' = 'application.note.update',
  'ApplicationNoteArchive' = 'application.note.archive',
  'ApplicationNoteRestore' = 'application.note.restore',
  'ApplicationNoteDestroy' = 'application.note.destroy',
  'ApplicationPhaseUpdate' = 'application.phase.update',
  'ApplicationPhaseChange' = 'application.phase.change',
  'ApplicationTaskIndex' = 'application.task.index',
  'ApplicationTaskCreate' = 'application.task.create',
  'ApplicationTaskUpdate' = 'application.task.update',
  'ApplicationTaskMove' = 'application.task.move',
  'ApplicationTaskArchive' = 'application.task.archive',
  'ApplicationTaskRestore' = 'application.task.restore',
  'ApplicationTaskDestroy' = 'application.task.destroy',
  'ApplicationAccountIndex' = 'application.account.index',
  'ApplicationAccountCreate' = 'application.account.create',
  'ApplicationAccountUpdate' = 'application.account.update',
  'ApplicationAccountProcess' = 'application.account.process',
  'ApplicationAccountArchive' = 'application.account.archive',
  'ApplicationAccountRestore' = 'application.account.restore',
  'ApplicationAccountDestroy' = 'application.account.destroy',
  'ExternalCostTypeIndex' = 'external.cost.type.index',
  'ExternalCostTypeCreate' = 'external.cost.type.create',
  'ExternalCostTypeView' = 'external.cost.type.view',
  'ExternalCostTypeUpdate' = 'external.cost.type.update',
  'ExternalCostTypeArchive' = 'external.cost.type.archive',
  'ExternalCostTypeRestore' = 'external.cost.type.restore',
  'ExternalCostTypeDestroy' = 'external.cost.type.destroy',
  'ApplicationPhaseTypeIndex' = 'application.phase.type.index',
  'ApplicationPhaseTypeCreate' = 'application.phase.type.create',
  'ApplicationPhaseTypeView' = 'application.phase.type.view',
  'ApplicationPhaseTypeUpdate' = 'application.phase.type.update',
  'ApplicationPhaseTypeArchive' = 'application.phase.type.archive',
  'ApplicationPhaseTypeRestore' = 'application.phase.type.restore',
  'ApplicationPhaseTypeDestroy' = 'application.phase.type.destroy',
  'ApplicationStatusTypeIndex' = 'application.status.type.index',
  'ApplicationStatusTypeCreate' = 'application.status.type.create',
  'ApplicationStatusTypeView' = 'application.status.type.view',
  'ApplicationStatusTypeUpdate' = 'application.status.type.update',
  'ApplicationStatusTypeArchive' = 'application.status.type.archive',
  'ApplicationStatusTypeRestore' = 'application.status.type.restore',
  'ApplicationStatusTypeDestroy' = 'application.status.type.destroy',
  'ApplicationTypeIndex' = 'application.type.index',
  'ApplicationTypeCreate' = 'application.type.create',
  'ApplicationTypeView' = 'application.type.view',
  'ApplicationTypeUpdate' = 'application.type.update',
  'ApplicationTypeArchive' = 'application.type.archive',
  'ApplicationTypeRestore' = 'application.type.restore',
  'ApplicationTypeDestroy' = 'application.type.destroy',
  'PhaseTypeIndex' = 'phase.type.index',
  'PhaseTypeCreate' = 'phase.type.create',
  'PhaseTypeView' = 'phase.type.view',
  'PhaseTypeUpdate' = 'phase.type.update',
  'PhaseTypeArchive' = 'phase.type.archive',
  'PhaseTypeRestore' = 'phase.type.restore',
  'PhaseTypeDestroy' = 'phase.type.destroy',
  'PhaseTaskIndex' = 'phase.task.index',
  'PhaseTaskCreate' = 'phase.task.create',
  'PhaseTaskView' = 'phase.task.view',
  'PhaseTaskUpdate' = 'phase.task.update',
  'PhaseTaskArchive' = 'phase.task.archive',
  'PhaseTaskRestore' = 'phase.task.restore',
  'PhaseTaskDestroy' = 'phase.task.destroy',
  'ApplicationsClientTypeIndex' = 'applications.client.type.index',
  'ApplicationsClientTypeCreate' = 'applications.client.type.create',
  'ApplicationsClientTypeView' = 'applications.client.type.view',
  'ApplicationsClientTypeUpdate' = 'applications.client.type.update',
  'ApplicationsClientTypeArchive' = 'applications.client.type.archive',
  'ApplicationsClientTypeRestore' = 'applications.client.type.restore',
  'ApplicationsClientTypeDestroy' = 'applications.client.type.destroy',
  'ApplicationsMemberTypeIndex' = 'applications.member.type.index',
  'ApplicationsMemberTypeCreate' = 'applications.member.type.create',
  'ApplicationsMemberTypeView' = 'applications.member.type.view',
  'ApplicationsMemberTypeUpdate' = 'applications.member.type.update',
  'ApplicationsMemberTypeArchive' = 'applications.member.type.archive',
  'ApplicationsMemberTypeRestore' = 'applications.member.type.restore',
  'ApplicationsMemberTypeDestroy' = 'applications.member.type.destroy',
  'PermissionIndex' = 'permission.index',
  'PermissionView' = 'permission.view',
  'PermissionUpdate' = 'permission.update',
  'UserSendmail' = 'user.sendmail',
  'UserRole' = 'user.role',
  'UserPermission' = 'user.permission',
  'UserHasPermission' = 'user.has.permission',
  'UserIndex' = 'user.index',
  'UserSearch' = 'user.search',
  'UserView' = 'user.view',
  'UserCreate' = 'user.create',
  'UserUpdate' = 'user.update',
  'UserVerify' = 'user.verify',
  'UserArchive' = 'user.archive',
  'UserRestore' = 'user.restore',
  'UserDestroy' = 'user.destroy',
  'UserPassword' = 'user.password',
  'RoleIndex' = 'role.index',
  'RoleView' = 'role.view',
  'RoleCreate' = 'role.create',
  'RoleUpdate' = 'role.update',
  'RoleArchive' = 'role.archive',
  'RoleRestore' = 'role.restore',
  'RoleDestroy' = 'role.destroy',
  'RoleGetPermissions' = 'role.get.permissions',
  'RoleSetPermission' = 'role.set.permission',
  'RoleUnsetPermission' = 'role.unset.permission',
  'BranchIndex' = 'branch.index',
  'BranchSearch' = 'branch.search',
  'BranchCreate' = 'branch.create',
  'BranchUpdate' = 'branch.update',
  'BranchView' = 'branch.view',
  'BranchArchive' = 'branch.archive',
  'BranchRestore' = 'branch.restore',
  'BranchDestroy' = 'branch.destroy',
  'BranchAddressTypeIndex' = 'branch.address.type.index',
  'BranchAddressTypeCreate' = 'branch.address.type.create',
  'BranchAddressTypeUpdate' = 'branch.address.type.update',
  'BranchAddressTypeView' = 'branch.address.type.view',
  'BranchAddressTypeArchive' = 'branch.address.type.archive',
  'BranchAddressTypeRestore' = 'branch.address.type.restore',
  'BranchAddressTypeDestroy' = 'branch.address.type.destroy',
  'BranchContactTypeIndex' = 'branch.contact.type.index',
  'BranchContactTypeCreate' = 'branch.contact.type.create',
  'BranchContactTypeUpdate' = 'branch.contact.type.update',
  'BranchContactTypeView' = 'branch.contact.type.view',
  'BranchContactTypeArchive' = 'branch.contact.type.archive',
  'BranchContactTypeRestore' = 'branch.contact.type.restore',
  'BranchContactTypeDestroy' = 'branch.contact.type.destroy',
  'DivisionIndex' = 'division.index',
  'DivisionSearch' = 'division.search',
  'DivisionCreate' = 'division.create',
  'DivisionUpdate' = 'division.update',
  'DivisionView' = 'division.view',
  'DivisionArchive' = 'division.archive',
  'DivisionRestore' = 'division.restore',
  'DivisionDestroy' = 'division.destroy',
  'AdhocIndex' = 'adhoc.index',
  'AdhocCreate' = 'adhoc.create',
  'AdhocUpdate' = 'adhoc.update',
  'AdhocView' = 'adhoc.view',
  'AdhocArchive' = 'adhoc.archive',
  'AdhocRestore' = 'adhoc.restore',
  'AdhocDestroy' = 'adhoc.destroy',
  'ClientIndex' = 'client.index',
  'ClientSearch' = 'client.search',
  'ClientCreate' = 'client.create',
  'ClientUpdate' = 'client.update',
  'ClientView' = 'client.view',
  'ClientArchive' = 'client.archive',
  'ClientRestore' = 'client.restore',
  'ClientDestroy' = 'client.destroy',
  'ClientAddressTypeIndex' = 'client.address.type.index',
  'ClientAddressTypeCreate' = 'client.address.type.create',
  'ClientAddressTypeUpdate' = 'client.address.type.update',
  'ClientAddressTypeView' = 'client.address.type.view',
  'ClientAddressTypeArchive' = 'client.address.type.archive',
  'ClientAddressTypeRestore' = 'client.address.type.restore',
  'ClientAddressTypeDestroy' = 'client.address.type.destroy',
  'ClientContactTypeIndex' = 'client.contact.type.index',
  'ClientContactTypeCreate' = 'client.contact.type.create',
  'ClientContactTypeUpdate' = 'client.contact.type.update',
  'ClientContactTypeView' = 'client.contact.type.view',
  'ClientContactTypeArchive' = 'client.contact.type.archive',
  'ClientContactTypeRestore' = 'client.contact.type.restore',
  'ClientContactTypeDestroy' = 'client.contact.type.destroy',
  'ConsultantIndex' = 'consultant.index',
  'ConsultantSearch' = 'consultant.search',
  'ConsultantCreate' = 'consultant.create',
  'ConsultantUpdate' = 'consultant.update',
  'ConsultantView' = 'consultant.view',
  'ConsultantArchive' = 'consultant.archive',
  'ConsultantRestore' = 'consultant.restore',
  'ConsultantDestroy' = 'consultant.destroy',
  'ConsultantAddressTypeIndex' = 'consultant.address.type.index',
  'ConsultantAddressTypeCreate' = 'consultant.address.type.create',
  'ConsultantAddressTypeUpdate' = 'consultant.address.type.update',
  'ConsultantAddressTypeView' = 'consultant.address.type.view',
  'ConsultantAddressTypeArchive' = 'consultant.address.type.archive',
  'ConsultantAddressTypeRestore' = 'consultant.address.type.restore',
  'ConsultantAddressTypeDestroy' = 'consultant.address.type.destroy',
  'ConsultantContactTypeIndex' = 'consultant.contact.type.index',
  'ConsultantContactTypeCreate' = 'consultant.contact.type.create',
  'ConsultantContactTypeUpdate' = 'consultant.contact.type.update',
  'ConsultantContactTypeView' = 'consultant.contact.type.view',
  'ConsultantContactTypeArchive' = 'consultant.contact.type.archive',
  'ConsultantContactTypeRestore' = 'consultant.contact.type.restore',
  'ConsultantContactTypeDestroy' = 'consultant.contact.type.destroy',
  'ConsultantPreferenceTypeIndex' = 'consultant.preference.type.index',
  'ConsultantPreferenceTypeCreate' = 'consultant.preference.type.create',
  'ConsultantPreferenceTypeUpdate' = 'consultant.preference.type.update',
  'ConsultantPreferenceTypeView' = 'consultant.preference.type.view',
  'ConsultantPreferenceTypeArchive' = 'consultant.preference.type.archive',
  'ConsultantPreferenceTypeRestore' = 'consultant.preference.type.restore',
  'ConsultantPreferenceTypeDestroy' = 'consultant.preference.type.destroy',
  'ConsultantServiceTypeIndex' = 'consultant.service.type.index',
  'ConsultantServiceTypeCreate' = 'consultant.service.type.create',
  'ConsultantServiceTypeUpdate' = 'consultant.service.type.update',
  'ConsultantServiceTypeView' = 'consultant.service.type.view',
  'ConsultantServiceTypeArchive' = 'consultant.service.type.archive',
  'ConsultantServiceTypeRestore' = 'consultant.service.type.restore',
  'ConsultantServiceTypeDestroy' = 'consultant.service.type.destroy',
  'CouncilIndex' = 'council.index',
  'CouncilSearch' = 'council.search',
  'CouncilCreate' = 'council.create',
  'CouncilUpdate' = 'council.update',
  'CouncilView' = 'council.view',
  'CouncilArchive' = 'council.archive',
  'CouncilRestore' = 'council.restore',
  'CouncilDestroy' = 'council.destroy',
  'CouncilAddressTypeIndex' = 'council.address.type.index',
  'CouncilAddressTypeCreate' = 'council.address.type.create',
  'CouncilAddressTypeUpdate' = 'council.address.type.update',
  'CouncilAddressTypeView' = 'council.address.type.view',
  'CouncilAddressTypeArchive' = 'council.address.type.archive',
  'CouncilAddressTypeRestore' = 'council.address.type.restore',
  'CouncilAddressTypeDestroy' = 'council.address.type.destroy',
  'CouncilContactTypeIndex' = 'council.contact.type.index',
  'CouncilContactTypeCreate' = 'council.contact.type.create',
  'CouncilContactTypeUpdate' = 'council.contact.type.update',
  'CouncilContactTypeView' = 'council.contact.type.view',
  'CouncilContactTypeArchive' = 'council.contact.type.archive',
  'CouncilContactTypeRestore' = 'council.contact.type.restore',
  'CouncilContactTypeDestroy' = 'council.contact.type.destroy',
  'OfficerIndex' = 'officer.index',
  'OfficerSearch' = 'officer.search',
  'OfficerCreate' = 'officer.create',
  'OfficerUpdate' = 'officer.update',
  'OfficerView' = 'officer.view',
  'OfficerArchive' = 'officer.archive',
  'OfficerRestore' = 'officer.restore',
  'OfficerDestroy' = 'officer.destroy',
  'OfficerAddressTypeIndex' = 'officer.address.type.index',
  'OfficerAddressTypeCreate' = 'officer.address.type.create',
  'OfficerAddressTypeUpdate' = 'officer.address.type.update',
  'OfficerAddressTypeView' = 'officer.address.type.view',
  'OfficerAddressTypeArchive' = 'officer.address.type.archive',
  'OfficerAddressTypeRestore' = 'officer.address.type.restore',
  'OfficerAddressTypeDestroy' = 'officer.address.type.destroy',
  'OfficerContactTypeIndex' = 'officer.contact.type.index',
  'OfficerContactTypeCreate' = 'officer.contact.type.create',
  'OfficerContactTypeUpdate' = 'officer.contact.type.update',
  'OfficerContactTypeView' = 'officer.contact.type.view',
  'OfficerContactTypeArchive' = 'officer.contact.type.archive',
  'OfficerContactTypeRestore' = 'officer.contact.type.restore',
  'OfficerContactTypeDestroy' = 'officer.contact.type.destroy',
  'OfficerPositionIndex' = 'officer.position.index',
  'OfficerPositionCreate' = 'officer.position.create',
  'OfficerPositionUpdate' = 'officer.position.update',
  'OfficerPositionView' = 'officer.position.view',
  'OfficerPositionArchive' = 'officer.position.archive',
  'OfficerPositionRestore' = 'officer.position.restore',
  'OfficerPositionDestroy' = 'officer.position.destroy',
  'AttendanceIndex' = 'attendance.index',
  'AttendanceCreate' = 'attendance.create',
  'AttendanceUpdate' = 'attendance.update',
  'AttendanceView' = 'attendance.view',
  'AttendanceArchive' = 'attendance.archive',
  'AttendanceRestore' = 'attendance.restore',
  'AttendanceDestroy' = 'attendance.destroy',
  'AttendanceCheckIn' = 'attendance.checkin',
  'AttendanceTypeIndex' = 'attendance.type.index',
  'AttendanceTypeCreate' = 'attendance.type.create',
  'AttendanceTypeUpdate' = 'attendance.type.update',
  'AttendanceTypeView' = 'attendance.type.view',
  'AttendanceTypeArchive' = 'attendance.type.archive',
  'AttendanceTypeRestore' = 'attendance.type.restore',
  'AttendanceTypeDestroy' = 'attendance.type.destroy',
  'AttendanceBreakTypeIndex' = 'attendance.break.type.index',
  'AttendanceBreakTypeCreate' = 'attendance.break.type.create',
  'AttendanceBreakTypeUpdate' = 'attendance.break.type.update',
  'AttendanceBreakTypeView' = 'attendance.break.type.view',
  'AttendanceBreakTypeArchive' = 'attendance.break.type.archive',
  'AttendanceBreakTypeRestore' = 'attendance.break.type.restore',
  'AttendanceBreakTypeDestroy' = 'attendance.break.type.destroy',
  'AttendanceLocationTypeIndex' = 'attendance.location.type.index',
  'AttendanceLocationTypeCreate' = 'attendance.location.type.create',
  'AttendanceLocationTypeUpdate' = 'attendance.location.type.update',
  'AttendanceLocationTypeView' = 'attendance.location.type.view',
  'AttendanceLocationTypeArchive' = 'attendance.location.type.archive',
  'AttendanceLocationTypeRestore' = 'attendance.location.type.restore',
  'AttendanceLocationTypeDestroy' = 'attendance.location.type.destroy',
  'AdhocTypeIndex' = 'adhoc.type.index',
  'AdhocTypeCreate' = 'adhoc.type.create',
  'AdhocTypeUpdate' = 'adhoc.type.update',
  'AdhocTypeView' = 'adhoc.type.view',
  'AdhocTypeArchive' = 'adhoc.type.archive',
  'AdhocTypeRestore' = 'adhoc.type.restore',
  'AdhocTypeDestroy' = 'adhoc.type.destroy',
  'UserRateIndex' = 'user.rate.index',
  'UserRateCreate' = 'user.rate.create',
  'UserRateUpdate' = 'user.rate.update',
  'UserRateView' = 'user.rate.view',
  'UserRateArchive' = 'user.rate.archive',
  'UserRateRestore' = 'user.rate.restore',
  'UserRateDestroy' = 'user.rate.destroy',
  'EmploymentTypeIndex' = 'employment.type.index',
  'EmploymentTypeCreate' = 'employment.type.create',
  'EmploymentTypeUpdate' = 'employment.type.update',
  'EmploymentTypeView' = 'employment.type.view',
  'EmploymentTypeArchive' = 'employment.type.archive',
  'EmploymentTypeRestore' = 'employment.type.restore',
  'EmploymentTypeDestroy' = 'employment.type.destroy',
  'DeterminationTypeIndex' = 'determination.type.index',
  'DeterminationTypeCreate' = 'determination.type.create',
  'DeterminationTypeUpdate' = 'determination.type.update',
  'DeterminationTypeView' = 'determination.type.view',
  'DeterminationTypeArchive' = 'determination.type.archive',
  'DeterminationTypeRestore' = 'determination.type.restore',
  'DeterminationTypeDestroy' = 'determination.type.destroy',
  'JobTypeIndex' = 'job.type.index',
  'JobTypeCreate' = 'job.type.create',
  'JobTypeUpdate' = 'job.type.update',
  'JobTypeView' = 'job.type.view',
  'JobTypeArchive' = 'job.type.archive',
  'JobTypeRestore' = 'job.type.restore',
  'JobTypeDestroy' = 'job.type.destroy',
  'NoteCategoryTypeIndex' = 'note.category.type.index',
  'NoteCategoryTypeCreate' = 'note.category.type.create',
  'NoteCategoryTypeUpdate' = 'note.category.type.update',
  'NoteCategoryTypeView' = 'note.category.type.view',
  'NoteCategoryTypeArchive' = 'note.category.type.archive',
  'NoteCategoryTypeRestore' = 'note.category.type.restore',
  'NoteCategoryTypeDestroy' = 'note.category.type.destroy',
  'CorrespondenceTypeIndex' = 'correspondence.type.index',
  'CorrespondenceTypeCreate' = 'correspondence.type.create',
  'CorrespondenceTypeUpdate' = 'correspondence.type.update',
  'CorrespondenceTypeView' = 'correspondence.type.view',
  'CorrespondenceTypeArchive' = 'correspondence.type.archive',
  'CorrespondenceTypeRestore' = 'correspondence.type.restore',
  'CorrespondenceTypeDestroy' = 'correspondence.type.destroy',
  'PaymentOptionIndex' = 'payment.option.index',
  'PaymentOptionCreate' = 'payment.option.create',
  'PaymentOptionUpdate' = 'payment.option.update',
  'PaymentOptionView' = 'payment.option.view',
  'PaymentOptionArchive' = 'payment.option.archive',
  'PaymentOptionRestore' = 'payment.option.restore',
  'PaymentOptionDestroy' = 'payment.option.destroy',
  'PaymentStatusTypeIndex' = 'payment.status.type.index',
  'PaymentStatusTypeCreate' = 'payment.status.type.create',
  'PaymentStatusTypeUpdate' = 'payment.status.type.update',
  'PaymentStatusTypeView' = 'payment.status.type.view',
  'PaymentStatusTypeArchive' = 'payment.status.type.archive',
  'PaymentStatusTypeRestore' = 'payment.status.type.restore',
  'PaymentStatusTypeDestroy' = 'payment.status.type.destroy',
  'TaskTypeIndex' = 'task.type.index',
  'TaskTypeCreate' = 'task.type.create',
  'TaskTypeUpdate' = 'task.type.update',
  'TaskTypeView' = 'task.type.view',
  'TaskTypeArchive' = 'task.type.archive',
  'TaskTypeRestore' = 'task.type.restore',
  'TaskTypeDestroy' = 'task.type.destroy',
  'TaskCostTypeIndex' = 'task.cost.type.index',
  'TaskCostTypeCreate' = 'task.cost.type.create',
  'TaskCostTypeUpdate' = 'task.cost.type.update',
  'TaskCostTypeView' = 'task.cost.type.view',
  'TaskCostTypeArchive' = 'task.cost.type.archive',
  'TaskCostTypeRestore' = 'task.cost.type.restore',
  'TaskCostTypeDestroy' = 'task.cost.type.destroy',
  'PredefinedTaskIndex' = 'predefined.task.index',
  'PredefinedTaskSearch' = 'predefined.task.search',
  'PredefinedTaskCreate' = 'predefined.task.create',
  'PredefinedTaskUpdate' = 'predefined.task.update',
  'PredefinedTaskView' = 'predefined.task.view',
  'PredefinedTaskArchive' = 'predefined.task.archive',
  'PredefinedTaskRestore' = 'predefined.task.restore',
  'PredefinedTaskDestroy' = 'predefined.task.destroy',
  'ReportApplicationHours' = 'report.application.hours',
  'ReportProjectHours' = 'report.project.hours',
  'ReportWorkProgress' = 'report.work.progress',
  'ReportApplicationProgress' = 'report.application.progress',
  'ReportAttendance' = 'report.attendance',
  'ReportAbsence' = 'report.absence',
  'ReportBilling' = 'report.billing',
  'ReportUserHours' = 'report.user.hours',
  'SettingIndex' = 'setting.index',
  'SettingSearch' = 'setting.search',
  'SettingCreate' = 'setting.create',
  'SettingUpdate' = 'setting.update',
  'SettingHaul' = 'setting.haul',
  'SettingView' = 'setting.view',
  'SettingArchive' = 'setting.archive',
  'SettingRestore' = 'setting.restore',
  'SettingDestroy' = 'setting.destroy',
  'InhouseTaskIndex' = 'inhouse.task.index',
  'InhouseTaskCreate' = 'inhouse.task.create',
  'InhouseTaskView' = 'inhouse.task.view',
  'InhouseTaskUpdate' = 'inhouse.task.update',
  'InhouseTaskStart' = 'inhouse.task.start',
  'InhouseTaskArchive' = 'inhouse.task.archive',
  'InhouseTaskRestore' = 'inhouse.task.restore',
  'InhouseTaskDestroy' = 'inhouse.task.destroy',
  'InhouseTaskGroupIndex' = 'inhouse.task.group.index',
  'InhouseTaskGroupCreate' = 'inhouse.task.group.create',
  'InhouseTaskGroupView' = 'inhouse.task.group.view',
  'InhouseTaskGroupUpdate' = 'inhouse.task.group.update',
  'InhouseTaskGroupArchive' = 'inhouse.task.group.archive',
  'InhouseTaskGroupRestore' = 'inhouse.task.group.restore',
  'InhouseTaskGroupDestroy' = 'inhouse.task.group.destroy',
  'InhouseTaskTypeIndex' = 'inhouse.task.type.index',
  'InhouseTaskTypeCreate' = 'inhouse.task.type.create',
  'InhouseTaskTypeView' = 'inhouse.task.type.view',
  'InhouseTaskTypeUpdate' = 'inhouse.task.type.update',
  'InhouseTaskTypeArchive' = 'inhouse.task.type.archive',
  'InhouseTaskTypeRestore' = 'inhouse.task.type.restore',
  'InhouseTaskTypeDestroy' = 'inhouse.task.type.destroy',
  'AbsenceIndex' = 'absence.index',
  'AbsenceCreate' = 'absence.create',
  'AbsenceUpdate' = 'absence.update',
  'AbsenceView' = 'absence.view',
  'AbsenceArchive' = 'absence.archive',
  'AbsenceRestore' = 'absence.restore',
  'AbsenceDestroy' = 'absence.destroy',
  'NotificationIndex' = 'notification.index',
  'NotificationDestroy' = 'notification.destroy'
}

export enum SettingEnum { 'String' = 1, 'Number' = 2, 'Boolean' = 3, 'Date' = 4, 'DateTime' = 5, 'Duration' = 6 }

export const RegisterRequest = {'name': {required: true}, 'email': {required: true}, 'password': {required: true}}
export const LoginRequest = {'email': {required: true}, 'password': {required: true}}
export const ForgotPasswordRequest = {'email': {required: true}}
export const ResetPasswordRequest = {
  'token': {required: true},
  'email': {required: true},
  'password': {required: true},
  'password_confirmation': {required: true}
}
export const AuthController = {
  'login': () => {
    return `login`;
  }, 'register': () => {
    return `register`;
  }, 'sendVerificationEmail': (payload) => {
    return axios.post(`send-verification-email`, payload);
  }, 'verifyEmail': (id, hash,) => {
    return `email/verify/${id}/${hash}`;
  }, 'forgotPassword': () => {
    return `forgot-password`;
  }, 'resetPassword': () => {
    return `password`;
  }, 'logout': (params?: any) => {
    return axios.get(`logout`, {params});
  }, 'permissions': (params?: any) => {
    return axios.get(`user-permission`, {params});
  }, 'pendingApplicationsMembershipTransfer': (params?: any) => {
    return axios.get(`pending-applications-membership-transfer`, {params});
  }
}
export const StorePredefinedTaskRequest = {
  'name': {required: true},
  'description': {required: false},
  'user_ids': {required: false},
  'branch_ids': {required: false},
  'division_ids': {required: false},
  'role_ids': {required: false}
}
export const StoreApplicationRequest = {
  'project_id': {required: true},
  'cost_type_id': {required: true},
  'fee': {required: true},
  'hourly_rate': {required: true},
  'payment_option': {required: true},
  'profit_margin': {required: true},
  'status_id': {required: false},
  'rfi_due_date': {required: false},
  'initiated_date': {required: true},
  'primary_client_id': {required: true},
  'other_client_ids': {required: false},
  'type_id': {required: true},
  'project_manager_id': {required: false},
  'deadline': {required: false},
  'determination_id': {required: false},
  'determination_date': {required: false},
  'determination_lapse': {required: false},
  'app_no': {required: false},
  'submission_date': {required: false},
  'refer_type_id': {required: false},
  'refer_client_id': {required: false},
  'refer_additional_info': {required: false}
}
export const StoreSettingRequest = {
  'group': {required: true},
  'name': {required: true},
  'locked': {required: true},
  'payload': {required: true},
  'type_id': {required: true},
  'description': {required: true},
  'display_order': {required: false}
}
export const StoreAdhocRequest = {
  'type_id': {required: false},
  'start': {required: true},
  'timezone': {required: true},
  'stop': {required: false},
  'resume': {required: false},
  'pause': {required: false},
  'duration': {required: false},
  'allocations': {required: false},
  'allocations.*.phase_id': {required: false},
  'allocations.*.category_id': {required: false},
  'allocations.*.note': {required: false},
  'allocations.*.duration': {required: false}
}
export const StoreUserRequest = {
  'name': {required: true},
  'email': {required: true},
  'password': {required: true},
  'password_confirmation': {required: true},
  'role_ids': {required: false},
  'branch_ids': {required: false},
  'division_ids': {required: false},
  'avatar': {required: false}
}
export const StoreInhouseTaskGroupRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false}
}
export const StoreConcreteTaskRequest = {
  'name': {required: true},
  'description': {required: false},
  'type_id': {required: true},
  'phase_id': {required: true},
  'cost_type_id': {required: false}
}
export const StoreDivisionRequest = {'name': {required: true}}
export const StoreConsultantContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false},
  'type_id': {required: true}
}
export const StoreClientRequest = {
  'first_name': {required: true},
  'last_name': {required: true},
  'company_name': {required: false},
  'abn': {required: false},
  'name_on_invoice': {required: false},
  'comment': {required: false},
  'phone': {required: false},
  'mobile': {required: false},
  'email': {required: true},
  'primary_address.address_line_1': {required: false},
  'primary_address.address_line_2': {required: false},
  'primary_address.locality': {required: false},
  'primary_address.region': {required: false},
  'primary_address.postcode': {required: false},
  'primary_address.description': {required: false},
  'primary_address.priority': {required: false},
  'primary_address.country': {required: false},
  'postal_address.address_line_1': {required: false},
  'postal_address.address_line_2': {required: false},
  'postal_address.locality': {required: false},
  'postal_address.region': {required: false},
  'postal_address.postcode': {required: false},
  'postal_address.description': {required: false},
  'postal_address.priority': {required: false},
  'postal_address.country': {required: false}
}
export const MoveConcreteTaskRequest = {
  'task': {required: true},
  'phase_id': {required: true},
  'note': {required: false}
}
export const StoreOfficerAddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false},
  'type_id': {required: true}
}
export const StoreCouncilContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false},
  'type_id': {required: true}
}
export const StoreRoleRequest = {'name': {required: true}, 'description': {required: false}}
export const StoreProjectRequest = {
  'address.address_line_1': {required: true},
  'address.address_line_2': {required: false},
  'address.locality': {required: true},
  'address.region': {required: true},
  'address.postcode': {required: true},
  'address.description': {required: false},
  'address.country': {required: false},
  'job_type_id': {required: false},
  'council_id': {required: true}
}
export const StorePhaseTypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false},
  'distribution_ratio': {required: false},
  'estimated_duration': {required: false},
  'application_type_ids': {required: false}
}
export const StoreApplicationsMembershipTransferRequest = {
  'application_id': {required: true},
  'transferee_id': {required: true},
  'charge_out_rate': {required: true},
  'member_type_id': {required: true}
}
export const InCompleteConcreteTaskRequest = {'task': {required: true}}
export const AddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false}
}
export const BreakInAttendanceRequest = {'type_id': {required: false}}
export const StoreDraftTaskRequest = {
  'name': {required: true},
  'description': {required: false},
  'type_id': {required: true},
  'phase_id': {required: true},
  'cost_type_id': {required: false}
}
export const StoreConsultantAddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false},
  'type_id': {required: true}
}
export const UpdateConcretePhaseRequest = {'estimated_duration': {required: true}}
export const StoreOfficerRequest = {
  'name': {required: true},
  'council_id': {required: true},
  'position': {required: false},
  'phone': {required: false},
  'mobile': {required: false},
  'email': {required: true},
  'primary_address.address_line_1': {required: false},
  'primary_address.address_line_2': {required: false},
  'primary_address.locality': {required: false},
  'primary_address.region': {required: false},
  'primary_address.postcode': {required: false},
  'primary_address.description': {required: false},
  'primary_address.priority': {required: false},
  'primary_address.country': {required: false},
  'postal_address.address_line_1': {required: false},
  'postal_address.address_line_2': {required: false},
  'postal_address.locality': {required: false},
  'postal_address.region': {required: false},
  'postal_address.postcode': {required: false},
  'postal_address.description': {required: false},
  'postal_address.priority': {required: false},
  'postal_address.country': {required: false}
}
export const StoreAttendanceRequest = {
  'employee_id': {required: true},
  'type_id': {required: false},
  'start': {required: true},
  'stop': {required: false},
  'breaks': {required: false},
  'breaks.*.start': {required: true},
  'breaks.*.stop': {required: false},
  'breaks.*.type_id': {required: true}
}
export const StoreExternalCostRequest = {
  'application_id': {required: true},
  'comment': {required: false},
  'estimated_cost': {required: false},
  'actual_cost': {required: false},
  'billable': {required: true},
  'type_id': {required: true}
}
export const OptionalApplicationRequest = {
  'project_id': {required: false},
  'cost_type_id': {required: false},
  'fee': {required: false},
  'hourly_rate': {required: false},
  'payment_option': {required: false},
  'profit_margin': {required: false},
  'status_id': {required: false},
  'rfi_due_date': {required: false},
  'initiated_date': {required: false},
  'primary_client_id': {required: false},
  'other_client_ids': {required: false},
  'type_id': {required: false},
  'project_manager_id': {required: false},
  'deadline': {required: false},
  'determination_id': {required: false},
  'determination_date': {required: false},
  'determination_lapse': {required: false},
  'app_no': {required: false},
  'submission_date': {required: false},
  'refer_type_id': {required: false},
  'refer_client_id': {required: false},
  'refer_additional_info': {required: false},
  'fields': {required: false}
}
export const UpdateAdhocAllocationRequest = {
  'duration': {required: true},
  'category_id': {required: false},
  'note': {required: false}
}
export const StorePaymentOptionRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false}
}
export const StoreApplicationNoteRequest = {
  'application_id': {required: true},
  'note': {required: true},
  'category_id': {required: false},
  'correspondence_id': {required: false}
}
export const UpdateExternalCostRequest = {
  'application_id': {required: true},
  'comment': {required: false},
  'estimated_cost': {required: false},
  'actual_cost': {required: false},
  'billable': {required: true},
  'type_id': {required: true}
}
export const StoreBranchRequest = {'name': {required: true}}
export const ProcessApplicationAccountRequest = {'processed_at': {required: true}}
export const StoreUserRateRequest = {
  'user_id': {required: true},
  'working_hours': {required: true},
  'billable_rate': {required: true},
  'charge_out_rate': {required: true},
  'salary_per_year': {required: true},
  'billable_entity': {required: true},
  'type_id': {required: false}
}
export const StoreOfficerContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false},
  'type_id': {required: true}
}
export const WriteOffConcreteTaskRequest = {'note': {required: true}}
export const CheckInAttendanceRequest = {'type_id': {required: false}}
export const StoreExternalCostTypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false},
  'estimated_cost': {required: false}
}
export const SyncPermissionRequest = {'permission': {required: false}}
export const MarkAsReadNotificationsRequest = {'notification_ids': {required: true}}
export const StoreClientContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false},
  'type_id': {required: true}
}
export const StoreBranchContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false},
  'type_id': {required: true}
}
export const CompleteConcreteTaskRequest = {'task': {required: true}}
export const StoreConsultantRequest = {
  'service_type_id': {required: true},
  'company_name': {required: false},
  'consultant_name': {required: true},
  'comment': {required: false},
  'rating_score': {required: false},
  'preference_type_id': {required: false},
  'phone': {required: false},
  'mobile': {required: false},
  'email': {required: true},
  'primary_address.address_line_1': {required: false},
  'primary_address.address_line_2': {required: false},
  'primary_address.locality': {required: false},
  'primary_address.region': {required: false},
  'primary_address.postcode': {required: false},
  'primary_address.description': {required: false},
  'primary_address.priority': {required: false},
  'primary_address.country': {required: false},
  'postal_address.address_line_1': {required: false},
  'postal_address.address_line_2': {required: false},
  'postal_address.locality': {required: false},
  'postal_address.region': {required: false},
  'postal_address.postcode': {required: false},
  'postal_address.description': {required: false},
  'postal_address.priority': {required: false},
  'postal_address.country': {required: false}
}
export const TypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false}
}
export const StartAdhocRequest = {
  'type_id': {required: false},
  'start': {required: true},
  'timezone': {required: true},
  'stop': {required: false},
  'duration': {required: false},
  'allocations': {required: false},
  'allocations.*.phase_id': {required: false},
  'allocations.*.category_id': {required: false},
  'allocations.*.note': {required: false},
  'allocations.*.duration': {required: false}
}
export const StorePhaseTaskRequest = {
  'name': {required: true},
  'description': {required: false},
  'type_id': {required: true},
  'phase_id': {required: true},
  'branch_ids': {required: false},
  'division_ids': {required: false},
  'role_ids': {required: false},
  'amount_percentage': {required: false}
}
export const BillConcreteTaskRequest = {
  'invoice_no': {required: true},
  'type_id': {required: true},
  'debit': {required: true},
  'credit': {required: true},
  'date': {required: true},
  'comment': {required: false},
  'task_ids': {required: true},
  'tasks': {required: true},
  'tasks.*.billed_amount': {required: true},
  'tasks.*.task_id': {required: true}
}
export const UpdateUserRequest = {
  'name': {required: true},
  'email': {required: true},
  'role_ids': {required: false},
  'branch_ids': {required: false},
  'division_ids': {required: false}
}
export const UpdateApplicationLightRequest = {
  'project_id': {required: true},
  'cost_type_id': {required: true},
  'fee': {required: true},
  'hourly_rate': {required: true},
  'payment_option': {required: true},
  'profit_margin': {required: true},
  'status_id': {required: false},
  'rfi_due_date': {required: false},
  'initiated_date': {required: true},
  'type_id': {required: true},
  'project_manager_id': {required: false},
  'deadline': {required: false},
  'determination_id': {required: false},
  'determination_date': {required: false},
  'determination_lapse': {required: false},
  'app_no': {required: false},
  'submission_date': {required: false},
  'refer_type_id': {required: false},
  'refer_client_id': {required: false},
  'refer_additional_info': {required: false}
}
export const UpdateRoleRequest = {'name': {required: true}, 'description': {required: false}}
export const UpdateApplicationMemberRequest = {'charge_out_rate': {required: true}}
export const StoreApplicationTypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false},
  'phase_ids': {required: true},
  'phases': {required: true},
  'phases.*.distribution_ratio': {required: true},
  'phases.*.phase_id': {required: true}
}
export const StopConcreteTaskRequest = {
  'task': {required: true},
  'tracking': {required: true},
  'note': {required: false}
}
export const UpdateConcreteTaskTrackingRequest = {
  'start': {required: true},
  'stop': {required: true},
  'tracking': {required: true},
  'note': {required: false}
}
export const PasswordUserRequest = {'password': {required: true}, 'password_confirmation': {required: true}}
export const StoreBranchAddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false},
  'type_id': {required: true}
}
export const StoreInhouseTaskRequest = {
  'group_id': {required: true},
  'type_id': {required: true},
  'start': {required: true},
  'stop': {required: false},
  'note': {required: false}
}
export const StartInhouseTaskRequest = {
  'group_id': {required: true},
  'type_id': {required: true},
  'note': {required: false}
}
export const ChangePermissionRequest = {'set_permission': {required: false}, 'unset_permission': {required: false}}
export const FillConcreteTaskRequest = {
  'phase_id': {required: true},
  'name': {required: true},
  'cost_type_id': {required: false},
  'start': {required: true},
  'stop': {required: true},
  'note': {required: false}
}
export const UpdateApplicationsClientRequest = {'client_type_id': {required: true}}
export const ContactRequest = {
  'value': {required: true},
  'description': {required: false},
  'priority': {required: false}
}
export const ChangePhaseRequest = {'phase_id': {required: true}}
export const SelfChangePasswordRequest = {
  'current': {required: true},
  'password': {required: true},
  'password_confirmation': {required: true}
}
export const StoreAbsenceRequest = {'user_id': {required: true}, 'date': {required: true}, 'note': {required: false}}
export const StoreClientAddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false},
  'type_id': {required: true}
}
export const StoreInhouseTaskTypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false},
  'group_id': {required: true}
}
export const UpdateApplicationAccountRequest = {
  'invoice_no': {required: true},
  'type_id': {required: true},
  'debit': {required: true},
  'credit': {required: false},
  'date': {required: true},
  'comment': {required: false}
}
export const StoreTaskCostTypeRequest = {
  'name': {required: true},
  'description': {required: false},
  'display_order': {required: false},
  'is_fixed': {required: false},
  'is_included': {required: false}
}
export const AcceptApplicationsMembershipTransferRequest = {'transfer': {required: true}, 'accept': {required: true}}
export const StoreCouncilAddressRequest = {
  'address_line_1': {required: true},
  'address_line_2': {required: false},
  'locality': {required: false},
  'region': {required: false},
  'postcode': {required: false},
  'description': {required: false},
  'priority': {required: false},
  'country': {required: false},
  'type_id': {required: true}
}
export const StoreCouncilRequest = {
  'name': {required: true},
  'website': {required: false},
  'comment': {required: false},
  'operation_from': {required: false},
  'operation_to': {required: false},
  'lep': {required: false},
  'dcp': {required: false},
  'map': {required: false},
  'form': {required: false},
  'tracking': {required: false},
  'phone': {required: false},
  'mobile': {required: false},
  'email': {required: true},
  'primary_address.address_line_1': {required: false},
  'primary_address.address_line_2': {required: false},
  'primary_address.locality': {required: false},
  'primary_address.region': {required: false},
  'primary_address.postcode': {required: false},
  'primary_address.description': {required: false},
  'primary_address.priority': {required: false},
  'primary_address.country': {required: false},
  'postal_address.address_line_1': {required: false},
  'postal_address.address_line_2': {required: false},
  'postal_address.locality': {required: false},
  'postal_address.region': {required: false},
  'postal_address.postcode': {required: false},
  'postal_address.description': {required: false},
  'postal_address.priority': {required: false},
  'postal_address.country': {required: false}
}
export const HaulSettingRequest = {'payload': {required: true}}
export const StoreApplicationAccountRequest = {
  'application_id': {required: true},
  'invoice_no': {required: true},
  'type_id': {required: true},
  'debit': {required: true},
  'credit': {required: false},
  'date': {required: true},
  'comment': {required: false}
}
export const ProjectController = {
  'index': (params?: any) => {
    return axios.get(`project`, {params});
  }, 'searchUser': (params?: any) => {
    return axios.get(`project/search-user`, {params});
  }, 'searchUserActive': (params?: any) => {
    return axios.get(`project/search-user-active`, {params});
  }, 'store': (payload) => {
    return axios.post(`project`, payload);
  }, 'show': (project, params?: any) => {
    return axios.get(`project/${project}`, {params});
  }, 'notes': (project, application, params?: any) => {
    return axios.get(`project/${project}/notes/${application}`, {params});
  }, 'update': (project, payload) => {
    return axios.put(`project/${project}`, payload);
  }, 'archive': (project, payload?: any) => {
    return axios.delete(`project/${project}`, {data: payload});
  }, 'restore': (project, payload) => {
    return axios.put(`project/${project}/restore`, payload);
  }, 'destroy': (project, payload?: any) => {
    return axios.delete(`project/${project}/destroy`, {data: payload});
  }, 'applications': (project, params?: any) => {
    return axios.get(`project/${project}/application`, {params});
  }
}
export const AdhocTypeController = {
  'index': (params?: any) => {
    return axios.get(`adhoc-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`adhoc-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`adhoc-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`adhoc-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`adhoc-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`adhoc-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`adhoc-type/${type}/destroy`, {data: payload});
  }
}
export const PaymentStatusTypeController = {
  'index': (params?: any) => {
    return axios.get(`payment-status-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`payment-status-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`payment-status-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`payment-status-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`payment-status-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`payment-status-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`payment-status-type/${type}/destroy`, {data: payload});
  }
}
export const DraftTaskController = {
  'index': (application, params?: any) => {
    return axios.get(`draft-task/${application}`, {params});
  }, 'import': (application, payload) => {
    return axios.put(`draft-task/${application}/import`, payload);
  }, 'store': (payload) => {
    return axios.post(`draft-task`, payload);
  }, 'update': (task, payload) => {
    return axios.put(`draft-task/${task}`, payload);
  }, 'destroy': (task, payload?: any) => {
    return axios.delete(`draft-task/${task}/destroy`, {data: payload});
  }
}
export const UserRateController = {
  'index': (params?: any) => {
    return axios.get(`user-rate`, {params});
  }, 'store': (payload) => {
    return axios.post(`user-rate`, payload);
  }, 'show': (rate, params?: any) => {
    return axios.get(`user-rate/${rate}`, {params});
  }, 'update': (rate, payload) => {
    return axios.put(`user-rate/${rate}`, payload);
  }, 'destroy': (rate, payload?: any) => {
    return axios.delete(`user-rate/${rate}`, {data: payload});
  }
}
export const SelfController = {
  'profile': (params?: any) => {
    return axios.get(`self/profile`, {params});
  }, 'activeApplicationTask': (params?: any) => {
    return axios.get(`self/active-application-task`, {params});
  }, 'activeInhouseTask': (params?: any) => {
    return axios.get(`self/active-inhouse-task`, {params});
  }, 'inHouseTasks': (params?: any) => {
    return axios.get(`self/inhouse-task`, {params});
  }, 'attendances': (params?: any) => {
    return axios.get(`self/attendance`, {params});
  }, 'storeAttendance': (payload) => {
    return axios.post(`self/attendance`, payload);
  }, 'updateAttendance': (attendance, payload) => {
    return axios.put(`self/attendance/${attendance}`, payload);
  }, 'archiveAttendance': (attendance, payload?: any) => {
    return axios.delete(`self/attendance/${attendance}`, {data: payload});
  }, 'restoreAttendance': (attendance, payload) => {
    return axios.put(`self/attendance/${attendance}/restore`, payload);
  }, 'destroyAttendance': (attendance, payload?: any) => {
    return axios.delete(`self/attendance/${attendance}/destroy`, {data: payload});
  }, 'absences': (params?: any) => {
    return axios.get(`self/absence`, {params});
  }, 'storeAbsence': (payload) => {
    return axios.post(`self/absence`, payload);
  }, 'notifications': (params?: any) => {
    return axios.get(`self/notifications`, {params});
  }, 'notificationsMarkAsRead': (payload) => {
    return axios.post(`self/notifications-mark-as-read`, payload);
  }, 'applicationAccounts': (params?: any) => {
    return axios.get(`self/accounts`, {params});
  }, 'changePassword': (payload) => {
    return axios.put(`self/password`, payload);
  }
}
export const PhaseTypeController = {
  'index': (params?: any) => {
    return axios.get(`phase-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`phase-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`phase-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`phase-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`phase-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`phase-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`phase-type/${type}/destroy`, {data: payload});
  }
}
export const ConcreteTaskTrackingController = {
  'update': (tracking, payload) => {
    return axios.put(`concrete-task-tracking/${tracking}`, payload);
  }, 'archive': (tracking, payload?: any) => {
    return axios.delete(`concrete-task-tracking/${tracking}`, {data: payload});
  }, 'restore': (tracking, payload) => {
    return axios.put(`concrete-task-tracking/${tracking}/restore`, payload);
  }, 'destroy': (tracking, payload?: any) => {
    return axios.delete(`concrete-task-tracking/${tracking}/destroy`, {data: payload});
  }, 'notes': (tracking, params?: any) => {
    return axios.get(`concrete-task-tracking/${tracking}/notes`, {params});
  }
}
export const ProjectAddressController = {
  'index': (project, params?: any) => {
    return axios.get(`project/${project}/address`, {params});
  }, 'store': (project, payload) => {
    return axios.post(`project/${project}/address`, payload);
  }, 'update': (project, address, payload) => {
    return axios.put(`project/${project}/address/${address}`, payload);
  }, 'destroy': (project, address, payload?: any) => {
    return axios.delete(`project/${project}/address/${address}`, {data: payload});
  }
}
export const ConsultantPreferenceTypeController = {
  'index': (params?: any) => {
    return axios.get(`consultant-preference-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`consultant-preference-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`consultant-preference-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`consultant-preference-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`consultant-preference-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`consultant-preference-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`consultant-preference-type/${type}/destroy`, {data: payload});
  }
}
export const OfficerController = {
  'index': (params?: any) => {
    return axios.get(`officer`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`officer/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`officer`, payload);
  }, 'show': (officer, params?: any) => {
    return axios.get(`officer/${officer}`, {params});
  }, 'update': (officer, payload) => {
    return axios.put(`officer/${officer}`, payload);
  }, 'archive': (officer, payload?: any) => {
    return axios.delete(`officer/${officer}`, {data: payload});
  }, 'restore': (officer, payload) => {
    return axios.put(`officer/${officer}/restore`, payload);
  }, 'destroy': (officer, payload?: any) => {
    return axios.delete(`officer/${officer}/destroy`, {data: payload});
  }
}
export const PredefinedTaskController = {
  'index': (params?: any) => {
    return axios.get(`predefined-task`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`predefined-task/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`predefined-task`, payload);
  }, 'show': (task, params?: any) => {
    return axios.get(`predefined-task/${task}`, {params});
  }, 'update': (task, payload) => {
    return axios.put(`predefined-task/${task}`, payload);
  }, 'archive': (task, payload?: any) => {
    return axios.delete(`predefined-task/${task}`, {data: payload});
  }, 'restore': (task, payload) => {
    return axios.put(`predefined-task/${task}/restore`, payload);
  }, 'destroy': (task, payload?: any) => {
    return axios.delete(`predefined-task/${task}/destroy`, {data: payload});
  }
}
export const PhaseTaskController = {
  'index': (params?: any) => {
    return axios.get(`phase-task`, {params});
  }, 'store': (payload) => {
    return axios.post(`phase-task`, payload);
  }, 'show': (task, params?: any) => {
    return axios.get(`phase-task/${task}`, {params});
  }, 'update': (task, payload) => {
    return axios.put(`phase-task/${task}`, payload);
  }, 'archive': (task, payload?: any) => {
    return axios.delete(`phase-task/${task}`, {data: payload});
  }, 'restore': (task, payload) => {
    return axios.put(`phase-task/${task}/restore`, payload);
  }, 'destroy': (task, payload?: any) => {
    return axios.delete(`phase-task/${task}/destroy`, {data: payload});
  }
}
export const JobTypeController = {
  'index': (params?: any) => {
    return axios.get(`job-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`job-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`job-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`job-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`job-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`job-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`job-type/${type}/destroy`, {data: payload});
  }
}
export const BranchContactTypeController = {
  'index': (params?: any) => {
    return axios.get(`branch-contact-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`branch-contact-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`branch-contact-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`branch-contact-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`branch-contact-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`branch-contact-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`branch-contact-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationsConsultantController = {
  'index': (application, params?: any) => {
    return axios.get(`applications-consultant/${application}`, {params});
  }, 'store': (application, consultant, payload) => {
    return axios.post(`applications-consultant/${application}/${consultant}`, payload);
  }, 'destroy': (application, consultant, payload?: any) => {
    return axios.delete(`applications-consultant/${application}/${consultant}`, {data: payload});
  }
}
export const TaskCostTypeController = {
  'index': (params?: any) => {
    return axios.get(`task-cost-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`task-cost-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`task-cost-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`task-cost-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`task-cost-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`task-cost-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`task-cost-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationsClientController = {
  'index': (application, params?: any) => {
    return axios.get(`applications-client/${application}`, {params});
  }, 'store': (application, client, payload) => {
    return axios.post(`applications-client/${application}/${client}`, payload);
  }, 'archive': (application, client, payload?: any) => {
    return axios.delete(`applications-client/${application}/${client}`, {data: payload});
  }, 'restore': (application, client, payload) => {
    return axios.put(`applications-client/${application}/${client}/restore`, payload);
  }, 'update': (application, client, payload) => {
    return axios.put(`applications-client/${application}/${client}`, payload);
  }
}
export const AdhocController = {
  'index': (params?: any) => {
    return axios.get(`adhoc`, {params});
  }, 'show': (adhoc, params?: any) => {
    return axios.get(`adhoc/${adhoc}`, {params});
  }, 'start': (payload) => {
    return axios.post(`adhoc/start`, payload);
  }, 'store': (payload) => {
    return axios.post(`adhoc`, payload);
  }, 'update': (adhoc, payload) => {
    return axios.put(`adhoc/${adhoc}/update`, payload);
  }, 'preupdate': (adhoc, payload) => {
    return axios.put(`adhoc/${adhoc}/preupdate`, payload);
  }, 'archive': (adhoc, payload?: any) => {
    return axios.delete(`adhoc/${adhoc}`, {data: payload});
  }, 'restore': (adhoc, payload) => {
    return axios.put(`adhoc/${adhoc}/restore`, payload);
  }, 'destroy': (adhoc, payload?: any) => {
    return axios.delete(`adhoc/${adhoc}/destroy`, {data: payload});
  }
}
export const CouncilOfficerController = {
  'index': (council, params?: any) => {
    return axios.get(`council/${council}/officer`, {params});
  }
}
export const AbsenceController = {
  'index': (params?: any) => {
    return axios.get(`absence`, {params});
  }, 'store': (payload) => {
    return axios.post(`absence`, payload);
  }, 'show': (absence, params?: any) => {
    return axios.get(`absence/${absence}`, {params});
  }, 'update': (absence, payload) => {
    return axios.put(`absence/${absence}`, payload);
  }, 'destroy': (absence, payload?: any) => {
    return axios.delete(`absence/${absence}`, {data: payload});
  }
}
export const OfficerAddressController = {
  'index': (officer, params?: any) => {
    return axios.get(`officer/${officer}/address`, {params});
  }, 'store': (officer, payload) => {
    return axios.post(`officer/${officer}/address`, payload);
  }, 'update': (officer, address, payload) => {
    return axios.put(`officer/${officer}/address/${address}`, payload);
  }, 'destroy': (officer, address, payload?: any) => {
    return axios.delete(`officer/${officer}/address/${address}`, {data: payload});
  }
}
export const ExternalCostTypeController = {
  'index': (params?: any) => {
    return axios.get(`cost-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`cost-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`cost-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`cost-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`cost-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`cost-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`cost-type/${type}/destroy`, {data: payload});
  }
}
export const AttendanceLocationTypeController = {
  'index': (params?: any) => {
    return axios.get(`attendance-location-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`attendance-location-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`attendance-location-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`attendance-location-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`attendance-location-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`attendance-location-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`attendance-location-type/${type}/destroy`, {data: payload});
  }
}
export const ClientController = {
  'index': (params?: any) => {
    return axios.get(`client`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`client/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`client`, payload);
  }, 'show': (client, params?: any) => {
    return axios.get(`client/${client}`, {params});
  }, 'update': (client, payload) => {
    return axios.put(`client/${client}`, payload);
  }, 'archive': (client, payload?: any) => {
    return axios.delete(`client/${client}`, {data: payload});
  }, 'restore': (client, payload) => {
    return axios.put(`client/${client}/restore`, payload);
  }, 'destroy': (client, payload?: any) => {
    return axios.delete(`client/${client}/destroy`, {data: payload});
  }
}
export const ApplicationExternalCostController = {
  'index': (application, params?: any) => {
    return axios.get(`application-external-cost/${application}`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-external-cost`, payload);
  }, 'update': (cost, payload) => {
    return axios.put(`application-external-cost/${cost}`, payload);
  }, 'archive': (cost, payload?: any) => {
    return axios.delete(`application-external-cost/${cost}`, {data: payload});
  }, 'restore': (cost, payload) => {
    return axios.put(`application-external-cost/${cost}/restore`, payload);
  }, 'destroy': (cost, payload?: any) => {
    return axios.delete(`application-external-cost/${cost}/destroy`, {data: payload});
  }, 'logs': (cost, params?: any) => {
    return axios.get(`application-external-cost/${cost}/logs`, {params});
  }
}
export const TransactionTypeController = {
  'index': (params?: any) => {
    return axios.get(`transaction-type`, {params});
  }
}
export const CouncilContactTypeController = {
  'index': (params?: any) => {
    return axios.get(`council-contact-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`council-contact-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`council-contact-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`council-contact-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`council-contact-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`council-contact-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`council-contact-type/${type}/destroy`, {data: payload});
  }
}
export const AttendanceBreakTypeController = {
  'index': (params?: any) => {
    return axios.get(`attendance-break-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`attendance-break-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`attendance-break-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`attendance-break-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`attendance-break-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`attendance-break-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`attendance-break-type/${type}/destroy`, {data: payload});
  }
}
export const CouncilAddressTypeController = {
  'index': (params?: any) => {
    return axios.get(`council-address-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`council-address-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`council-address-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`council-address-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`council-address-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`council-address-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`council-address-type/${type}/destroy`, {data: payload});
  }
}
export const PaymentOptionController = {
  'index': (params?: any) => {
    return axios.get(`payment-option`, {params});
  }, 'store': (payload) => {
    return axios.post(`payment-option`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`payment-option/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`payment-option/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`payment-option/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`payment-option/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`payment-option/${type}/destroy`, {data: payload});
  }
}
export const ApplicationNoteController = {
  'index': (application, params?: any) => {
    return axios.get(`application-note/${application}`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-note`, payload);
  }, 'update': (note, payload) => {
    return axios.put(`application-note/${note}`, payload);
  }, 'archive': (note, payload?: any) => {
    return axios.delete(`application-note/${note}`, {data: payload});
  }, 'restore': (note, payload) => {
    return axios.put(`application-note/${note}/restore`, payload);
  }, 'destroy': (note, payload?: any) => {
    return axios.delete(`application-note/${note}/destroy`, {data: payload});
  }, 'logs': (note, params?: any) => {
    return axios.get(`application-note/${note}/logs`, {params});
  }
}
export const NoteCategoryTypeController = {
  'index': (params?: any) => {
    return axios.get(`note-category-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`note-category-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`note-category-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`note-category-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`note-category-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`note-category-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`note-category-type/${type}/destroy`, {data: payload});
  }
}
export const OfficerAddressTypeController = {
  'index': (params?: any) => {
    return axios.get(`officer-address-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`officer-address-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`officer-address-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`officer-address-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`officer-address-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`officer-address-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`officer-address-type/${type}/destroy`, {data: payload});
  }
}
export const AttendanceTypeController = {
  'index': (params?: any) => {
    return axios.get(`attendance-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`attendance-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`attendance-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`attendance-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`attendance-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`attendance-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`attendance-type/${type}/destroy`, {data: payload});
  }
}
export const DashboardController = {
  'workInProgress': (params?: any) => {
    return axios.get(`dashboard/work-in-progress`, {params});
  }, 'userStatistics': (params?: any) => {
    return axios.get(`dashboard/user-statistics`, {params});
  }, 'userInProgress': (user, params?: any) => {
    return axios.get(`dashboard/user-statistics/${user}`, {params});
  }, 'applicationsByStatus': (user, params?: any) => {
    return axios.get(`dashboard/user-applications/${user}`, {params});
  }, 'accounts': (params?: any) => {
    return axios.get(`dashboard/accounts`, {params});
  }
}
export const ReferTypeController = {
  'index': (params?: any) => {
    return axios.get(`refer-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`refer-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`refer-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`refer-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`refer-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`refer-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`refer-type/${type}/destroy`, {data: payload});
  }
}
export const OfficerContactTypeController = {
  'index': (params?: any) => {
    return axios.get(`officer-contact-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`officer-contact-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`officer-contact-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`officer-contact-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`officer-contact-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`officer-contact-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`officer-contact-type/${type}/destroy`, {data: payload});
  }
}
export const InhouseTaskGroupController = {
  'index': (params?: any) => {
    return axios.get(`inhouse-task-group`, {params});
  }, 'indexWithTypes': (params?: any) => {
    return axios.get(`inhouse-task-group/index-with-types`, {params});
  }, 'store': (payload) => {
    return axios.post(`inhouse-task-group`, payload);
  }, 'show': (group, params?: any) => {
    return axios.get(`inhouse-task-group/${group}`, {params});
  }, 'update': (group, payload) => {
    return axios.put(`inhouse-task-group/${group}`, payload);
  }, 'archive': (group, payload?: any) => {
    return axios.delete(`inhouse-task-group/${group}`, {data: payload});
  }, 'restore': (group, payload) => {
    return axios.put(`inhouse-task-group/${group}/restore`, payload);
  }, 'destroy': (group, payload?: any) => {
    return axios.delete(`inhouse-task-group/${group}/destroy`, {data: payload});
  }
}
export const PermissionGroupController = {
  'index': (params?: any) => {
    return axios.get(`permission-group`, {params});
  }, 'update': (permission, payload) => {
    return axios.put(`permission-group/${permission}`, payload);
  }
}
export const InhouseTaskController = {
  'index': (params?: any) => {
    return axios.get(`inhouse-task`, {params});
  }, 'start': (payload) => {
    return axios.post(`inhouse-task/start`, payload);
  }, 'show': (task, params?: any) => {
    return axios.get(`inhouse-task/${task}`, {params});
  }, 'stop': (task, payload) => {
    return axios.put(`inhouse-task/${task}/stop`, payload);
  }, 'store': (payload) => {
    return axios.post(`inhouse-task`, payload);
  }, 'update': (task, payload) => {
    return axios.put(`inhouse-task/${task}`, payload);
  }, 'archive': (task, payload?: any) => {
    return axios.delete(`inhouse-task/${task}`, {data: payload});
  }, 'restore': (task, payload) => {
    return axios.put(`inhouse-task/${task}/restore`, payload);
  }, 'destroy': (task, payload?: any) => {
    return axios.delete(`inhouse-task/${task}/destroy`, {data: payload});
  }
}
export const BranchAddressTypeController = {
  'index': (params?: any) => {
    return axios.get(`branch-address-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`branch-address-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`branch-address-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`branch-address-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`branch-address-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`branch-address-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`branch-address-type/${type}/destroy`, {data: payload});
  }
}
export const ConsultantController = {
  'index': (params?: any) => {
    return axios.get(`consultant`, {params});
  }, 'privateCertifiers': (params?: any) => {
    return axios.get(`consultant/private-certifiers`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`consultant/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`consultant`, payload);
  }, 'show': (consultant, params?: any) => {
    return axios.get(`consultant/${consultant}`, {params});
  }, 'update': (consultant, payload) => {
    return axios.put(`consultant/${consultant}`, payload);
  }, 'archive': (consultant, payload?: any) => {
    return axios.delete(`consultant/${consultant}`, {data: payload});
  }, 'restore': (consultant, payload) => {
    return axios.put(`consultant/${consultant}/restore`, payload);
  }, 'destroy': (consultant, payload?: any) => {
    return axios.delete(`consultant/${consultant}/destroy`, {data: payload});
  }
}
export const ApplicationCostTypeController = {
  'index': (params?: any) => {
    return axios.get(`application-cost-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-cost-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`application-cost-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`application-cost-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`application-cost-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`application-cost-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`application-cost-type/${type}/destroy`, {data: payload});
  }
}
export const OfficerContactController = {
  'index': (officer, params?: any) => {
    return axios.get(`officer/${officer}/contact`, {params});
  }, 'store': (officer, payload) => {
    return axios.post(`officer/${officer}/contact`, payload);
  }, 'update': (officer, contact, payload) => {
    return axios.put(`officer/${officer}/contact/${contact}`, payload);
  }, 'destroy': (officer, contact, payload?: any) => {
    return axios.delete(`officer/${officer}/contact/${contact}`, {data: payload});
  }
}
export const BranchContactController = {
  'index': (branch, params?: any) => {
    return axios.get(`branch/${branch}/contact`, {params});
  }, 'store': (branch, payload) => {
    return axios.post(`branch/${branch}/contact`, payload);
  }, 'update': (branch, contact, payload) => {
    return axios.put(`branch/${branch}/contact/${contact}`, payload);
  }, 'destroy': (branch, contact, payload?: any) => {
    return axios.delete(`branch/${branch}/contact/${contact}`, {data: payload});
  }
}
export const ApplicationTypeController = {
  'index': (params?: any) => {
    return axios.get(`application-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`application-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`application-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`application-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`application-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`application-type/${type}/destroy`, {data: payload});
  }
}
export const ClientAddressController = {
  'index': (client, params?: any) => {
    return axios.get(`client/${client}/address`, {params});
  }, 'store': (client, payload) => {
    return axios.post(`client/${client}/address`, payload);
  }, 'update': (client, address, payload) => {
    return axios.put(`client/${client}/address/${address}`, payload);
  }, 'destroy': (client, address, payload?: any) => {
    return axios.delete(`client/${client}/address/${address}`, {data: payload});
  }
}
export const ConsultantAddressController = {
  'index': (consultant, params?: any) => {
    return axios.get(`consultant/${consultant}/address`, {params});
  }, 'store': (consultant, payload) => {
    return axios.post(`consultant/${consultant}/address`, payload);
  }, 'update': (consultant, address, payload) => {
    return axios.put(`consultant/${consultant}/address/${address}`, payload);
  }, 'destroy': (consultant, address, payload?: any) => {
    return axios.delete(`consultant/${consultant}/address/${address}`, {data: payload});
  }
}
export const ApplicationStatusTypeController = {
  'index': (params?: any) => {
    return axios.get(`application-status-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-status-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`application-status-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`application-status-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`application-status-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`application-status-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`application-status-type/${type}/destroy`, {data: payload});
  }
}
export const UserAvatarController = {
  'thumb': (user, avatar,) => {
    return `user/${user}/avatar/${avatar}/thumb`;
  }, 'preview': (user, avatar,) => {
    return `user/${user}/avatar/${avatar}/preview`;
  }, 'download': (user, avatar,) => {
    return `user/${user}/avatar/${avatar}`;
  }
}
export const ConcretePhaseController = {
  'update': (phase, payload) => {
    return axios.put(`concrete-phase/${phase}`, payload);
  }, 'adhocAllocations': (phase, params?: any) => {
    return axios.get(`concrete-phase/${phase}/adhoc-allocations`, {params});
  }
}
export const ConcreteTaskController = {
  'index': (application, params?: any) => {
    return axios.get(`concrete-task/${application}`, {params});
  }, 'launch': (application, payload) => {
    return axios.put(`concrete-task/${application}/launch`, payload);
  }, 'store': (payload) => {
    return axios.post(`concrete-task`, payload);
  }, 'update': (task, payload) => {
    return axios.put(`concrete-task/${task}`, payload);
  }, 'writeOff': (task, payload?: any) => {
    return axios.delete(`concrete-task/${task}/write-off`, {data: payload});
  }, 'writeOffReverse': (task, payload?: any) => {
    return axios.delete(`concrete-task/${task}/write-off-reverse`, {data: payload});
  }, 'archive': (task, payload?: any) => {
    return axios.delete(`concrete-task/${task}`, {data: payload});
  }, 'restore': (task, payload) => {
    return axios.put(`concrete-task/${task}/restore`, payload);
  }, 'destroy': (task, payload?: any) => {
    return axios.delete(`concrete-task/${task}/destroy`, {data: payload});
  }, 'tracking': (task, params?: any) => {
    return axios.get(`concrete-task/${task}/tracking`, {params});
  }, 'start': (task, payload) => {
    return axios.put(`concrete-task/${task}/start`, payload);
  }, 'stop': (task, tracking, payload) => {
    return axios.put(`concrete-task/${task}/stop/${tracking}`, payload);
  }, 'complete': (task, payload) => {
    return axios.put(`concrete-task/${task}/complete`, payload);
  }, 'incomplete': (task, payload) => {
    return axios.put(`concrete-task/${task}/incomplete`, payload);
  }, 'move': (task, payload) => {
    return axios.put(`concrete-task/${task}/move`, payload);
  }, 'notes': (task, params?: any) => {
    return axios.get(`concrete-task/${task}/notes`, {params});
  }, 'completedTasks': (application, params?: any) => {
    return axios.get(`concrete-task/${application}/completed-tasks`, {params});
  }, 'bill': (application, payload) => {
    return axios.put(`concrete-task/${application}/bill`, payload);
  }, 'fill': (payload) => {
    return axios.post(`concrete-task/fill`, payload);
  }, 'logs': (task, params?: any) => {
    return axios.get(`concrete-task/${task}/logs`, {params});
  }
}
export const UserController = {
  'index': (params?: any) => {
    return axios.get(`user`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`user/search`, {params});
  }, 'checkedIn': (params?: any) => {
    return axios.get(`user/checked-in`, {params});
  }, 'show': (user, params?: any) => {
    return axios.get(`user/${user}`, {params});
  }, 'permissions': (user, params?: any) => {
    return axios.get(`user/${user}/permissions`, {params});
  }, 'store': (payload) => {
    return axios.postForm(`user`, payload);
  }, 'update': (user, payload) => {
    return axios.post(`user/${user}`, payload);
  }, 'password': (user, payload) => {
    return axios.put(`user/${user}/password`, payload);
  }, 'archive': (user, payload?: any) => {
    return axios.delete(`user/${user}`, {data: payload});
  }, 'restore': (user, payload) => {
    return axios.put(`user/${user}/restore`, payload);
  }, 'destroy': (user, payload?: any) => {
    return axios.delete(`user/${user}/destroy`, {data: payload});
  }, 'verifyEmail': (user, payload) => {
    return axios.post(`user/${user}/verify`, payload);
  }
}
export const DraftPhaseController = {
  'update': (phase, payload) => {
    return axios.put(`draft-phase/${phase}`, payload);
  }
}
export const EmploymentTypeController = {
  'index': (params?: any) => {
    return axios.get(`user-rate-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`user-rate-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`user-rate-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`user-rate-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`user-rate-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`user-rate-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`user-rate-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationController = {
  'index': (params?: any) => {
    return axios.get(`application`, {params});
  }, 'store': (payload) => {
    return axios.post(`application`, payload);
  }, 'show': (application, params?: any) => {
    return axios.get(`application/${application}`, {params});
  }, 'stats': (application, params?: any) => {
    return axios.get(`application/${application}/stats`, {params});
  }, 'update': (application, payload) => {
    return axios.put(`application/${application}`, payload);
  }, 'updateLight': (application, payload) => {
    return axios.put(`application/${application}/update-light`, payload);
  }, 'updateOptional': (application, payload) => {
    return axios.put(`application/${application}/update-optional`, payload);
  }, 'archive': (application, payload?: any) => {
    return axios.delete(`application/${application}`, {data: payload});
  }, 'restore': (application, payload) => {
    return axios.put(`application/${application}/restore`, payload);
  }, 'destroy': (application, payload?: any) => {
    return axios.delete(`application/${application}/destroy`, {data: payload});
  }, 'changePhase': (application, payload) => {
    return axios.put(`application/${application}/change-phase`, payload);
  }, 'accounts': (application, params?: any) => {
    return axios.get(`application/${application}/accounts`, {params});
  }, 'architectFee': (application, params?: any) => {
    return axios.get(`application/${application}/architect-fee`, {params});
  }, 'syncToSpecMySpace': (application, payload) => {
    return axios.post(`application/${application}/spec-my-space/sync`, payload);
  }, 'viewOnSpecMySpace': (application, payload) => {
    return axios.post(`application/${application}/spec-my-space/view`, payload);
  }, 'setConsentAuthorityCouncilOfficer': (application, officerId, payload) => {
    return axios.put(`application/${application}/consent-authority/council-officer/${officerId}`, payload);
  }, 'setConsentAuthorityPrivateCertifier': (application, consultantId, payload) => {
    return axios.put(`application/${application}/consent-authority/private-certifier/${consultantId}`, payload);
  }, 'removeConsentAuthority': (application, payload?: any) => {
    return axios.delete(`application/${application}/consent-authority`, {data: payload});
  }
}
export const NotificationController = {
  'index': (params?: any) => {
    return axios.get(`notification`, {params});
  }, 'destroy': (notification, payload?: any) => {
    return axios.delete(`notification/${notification}/destroy`, {data: payload});
  }
}
export const CouncilContactController = {
  'index': (council, params?: any) => {
    return axios.get(`council/${council}/contact`, {params});
  }, 'store': (council, payload) => {
    return axios.post(`council/${council}/contact`, payload);
  }, 'update': (council, contact, payload) => {
    return axios.put(`council/${council}/contact/${contact}`, payload);
  }, 'destroy': (council, contact, payload?: any) => {
    return axios.delete(`council/${council}/contact/${contact}`, {data: payload});
  }
}
export const ConsultantContactController = {
  'index': (consultant, params?: any) => {
    return axios.get(`consultant/${consultant}/contact`, {params});
  }, 'store': (consultant, payload) => {
    return axios.post(`consultant/${consultant}/contact`, payload);
  }, 'update': (consultant, contact, payload) => {
    return axios.put(`consultant/${consultant}/contact/${contact}`, payload);
  }, 'destroy': (consultant, contact, payload?: any) => {
    return axios.delete(`consultant/${consultant}/contact/${contact}`, {data: payload});
  }
}
export const ConsultantAddressTypeController = {
  'index': (params?: any) => {
    return axios.get(`consultant-address-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`consultant-address-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`consultant-address-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`consultant-address-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`consultant-address-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`consultant-address-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`consultant-address-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationsClientTypeController = {
  'index': (params?: any) => {
    return axios.get(`applications-client-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`applications-client-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`applications-client-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`applications-client-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`applications-client-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`applications-client-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`applications-client-type/${type}/destroy`, {data: payload});
  }
}
export const DivisionController = {
  'index': (params?: any) => {
    return axios.get(`division`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`division/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`division`, payload);
  }, 'show': (division, params?: any) => {
    return axios.get(`division/${division}`, {params});
  }, 'update': (division, payload) => {
    return axios.put(`division/${division}`, payload);
  }, 'archive': (division, payload?: any) => {
    return axios.delete(`division/${division}`, {data: payload});
  }, 'restore': (division, payload) => {
    return axios.put(`division/${division}/restore`, payload);
  }, 'destroy': (division, payload?: any) => {
    return axios.delete(`division/${division}/destroy`, {data: payload});
  }
}
export const OfficerPositionController = {
  'index': (params?: any) => {
    return axios.get(`officer-position`, {params});
  }, 'store': (payload) => {
    return axios.post(`officer-position`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`officer-position/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`officer-position/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`officer-position/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`officer-position/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`officer-position/${type}/destroy`, {data: payload});
  }
}
export const CouncilController = {
  'index': (params?: any) => {
    return axios.get(`council`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`council/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`council`, payload);
  }, 'show': (council, params?: any) => {
    return axios.get(`council/${council}`, {params});
  }, 'update': (council, payload) => {
    return axios.put(`council/${council}`, payload);
  }, 'archive': (council, payload?: any) => {
    return axios.delete(`council/${council}`, {data: payload});
  }, 'restore': (council, payload) => {
    return axios.put(`council/${council}/restore`, payload);
  }, 'destroy': (council, payload?: any) => {
    return axios.delete(`council/${council}/destroy`, {data: payload});
  }
}
export const ApplicationAccountController = {
  'index': (params?: any) => {
    return axios.get(`application-account`, {params});
  }, 'store': (payload) => {
    return axios.post(`application-account`, payload);
  }, 'update': (account, payload) => {
    return axios.put(`application-account/${account}`, payload);
  }, 'process': (account, payload) => {
    return axios.put(`application-account/${account}/process`, payload);
  }, 'archive': (account, payload?: any) => {
    return axios.delete(`application-account/${account}`, {data: payload});
  }, 'restore': (account, payload) => {
    return axios.put(`application-account/${account}/restore`, payload);
  }, 'destroy': (account, payload?: any) => {
    return axios.delete(`application-account/${account}/destroy`, {data: payload});
  }
}
export const TaskTypeController = {
  'index': (params?: any) => {
    return axios.get(`task-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`task-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`task-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`task-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`task-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`task-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`task-type/${type}/destroy`, {data: payload});
  }
}
export const ClientContactTypeController = {
  'index': (params?: any) => {
    return axios.get(`client-contact-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`client-contact-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`client-contact-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`client-contact-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`client-contact-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`client-contact-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`client-contact-type/${type}/destroy`, {data: payload});
  }
}
export const CouncilAddressController = {
  'index': (council, params?: any) => {
    return axios.get(`council/${council}/address`, {params});
  }, 'store': (council, payload) => {
    return axios.post(`council/${council}/address`, payload);
  }, 'update': (council, address, payload) => {
    return axios.put(`council/${council}/address/${address}`, payload);
  }, 'destroy': (council, address, payload?: any) => {
    return axios.delete(`council/${council}/address/${address}`, {data: payload});
  }
}
export const RoleController = {
  'index': (params?: any) => {
    return axios.get(`role`, {params});
  }, 'get': (role, params?: any) => {
    return axios.get(`role/${role}`, {params});
  }, 'store': (payload) => {
    return axios.post(`role`, payload);
  }, 'update': (role, payload) => {
    return axios.put(`role/${role}`, payload);
  }, 'syncPermission': (role, payload) => {
    return axios.put(`role/${role}/sync-permission`, payload);
  }, 'changePermission': (role, payload) => {
    return axios.put(`role/${role}/change-permission`, payload);
  }, 'archive': (role, payload?: any) => {
    return axios.delete(`role/${role}`, {data: payload});
  }, 'restore': (role, payload) => {
    return axios.put(`role/${role}/restore`, payload);
  }, 'destroy': (role, payload?: any) => {
    return axios.delete(`role/${role}/destroy`, {data: payload});
  }
}
export const InhouseTaskTypeController = {
  'index': (params?: any) => {
    return axios.get(`inhouse-task-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`inhouse-task-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`inhouse-task-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`inhouse-task-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`inhouse-task-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`inhouse-task-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`inhouse-task-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationsMembershipTransferController = {
  'index': (params?: any) => {
    return axios.get(`applications-membership-transfer`, {params});
  }, 'store': (payload) => {
    return axios.post(`applications-membership-transfer`, payload);
  }, 'accept': (transfer, payload) => {
    return axios.put(`applications-membership-transfer/${transfer}/accept`, payload);
  }
}
export const ClientAddressTypeController = {
  'index': (params?: any) => {
    return axios.get(`client-address-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`client-address-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`client-address-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`client-address-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`client-address-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`client-address-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`client-address-type/${type}/destroy`, {data: payload});
  }
}
export const BranchAddressController = {
  'index': (branch, params?: any) => {
    return axios.get(`branch/${branch}/address`, {params});
  }, 'store': (branch, payload) => {
    return axios.post(`branch/${branch}/address`, payload);
  }, 'update': (branch, address, payload) => {
    return axios.put(`branch/${branch}/address/${address}`, payload);
  }, 'destroy': (branch, address, payload?: any) => {
    return axios.delete(`branch/${branch}/address/${address}`, {data: payload});
  }
}
export const AttendanceController = {
  'index': (params?: any) => {
    return axios.get(`attendance`, {params});
  }, 'store': (payload) => {
    return axios.post(`attendance`, payload);
  }, 'show': (attendanceId, params?: any) => {
    return axios.get(`attendance/${attendanceId}`, {params});
  }, 'update': (attendanceId, payload) => {
    return axios.put(`attendance/${attendanceId}`, payload);
  }, 'archive': (attendanceId, payload?: any) => {
    return axios.delete(`attendance/${attendanceId}`, {data: payload});
  }, 'restore': (attendanceId, payload) => {
    return axios.put(`attendance/${attendanceId}/restore`, payload);
  }, 'destroy': (attendanceId, payload?: any) => {
    return axios.delete(`attendance/${attendanceId}/destroy`, {data: payload});
  }, 'checkIn': (payload) => {
    return axios.post(`attendance/check-in`, payload);
  }, 'breakIn': (attendance, payload) => {
    return axios.put(`attendance/${attendance}/break-in`, payload);
  }, 'breakOut': (attendance, brk, payload) => {
    return axios.put(`attendance/${attendance}/break-out/${brk}`, payload);
  }, 'checkOut': (attendance, payload) => {
    return axios.put(`attendance/${attendance}/check-out`, payload);
  }, 'latest': (params?: any) => {
    return axios.get(`attendance/latest`, {params});
  }
}
export const ReportController = {
  'applicationHours': (params?: any) => {
    return axios.get(`report/application-hours`, {params});
  }, 'projectHours': (params?: any) => {
    return axios.get(`report/project-hours`, {params});
  }, 'workInProgress': (params?: any) => {
    return axios.get(`report/wip`, {params});
  }, 'applicationInProgress': (params?: any) => {
    return axios.get(`report/aip`, {params});
  }, 'attendance': (params?: any) => {
    return axios.get(`report/attendance`, {params});
  }, 'absence': (params?: any) => {
    return axios.get(`report/absence`, {params});
  }, 'stakeholder': (params?: any) => {
    return axios.get(`report/stakeholder`, {params});
  }, 'billing': (params?: any) => {
    return axios.get(`report/billing`, {params});
  }, 'userHours': (params?: any) => {
    return axios.get(`report/user-hours`, {params});
  }
}
export const DeterminationTypeController = {
  'index': (params?: any) => {
    return axios.get(`determination-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`determination-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`determination-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`determination-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`determination-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`determination-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`determination-type/${type}/destroy`, {data: payload});
  }
}
export const BranchController = {
  'index': (params?: any) => {
    return axios.get(`branch`, {params});
  }, 'search': (params?: any) => {
    return axios.get(`branch/search`, {params});
  }, 'store': (payload) => {
    return axios.post(`branch`, payload);
  }, 'show': (branch, params?: any) => {
    return axios.get(`branch/${branch}`, {params});
  }, 'update': (branch, payload) => {
    return axios.put(`branch/${branch}`, payload);
  }, 'archive': (branch, payload?: any) => {
    return axios.delete(`branch/${branch}`, {data: payload});
  }, 'restore': (branch, payload) => {
    return axios.put(`branch/${branch}/restore`, payload);
  }, 'destroy': (branch, payload?: any) => {
    return axios.delete(`branch/${branch}/destroy`, {data: payload});
  }
}
export const AdhocAllocationController = {
  'update': (allocation, payload) => {
    return axios.put(`adhoc-allocation/${allocation}`, payload);
  }, 'archive': (allocation, payload?: any) => {
    return axios.delete(`adhoc-allocation/${allocation}`, {data: payload});
  }, 'restore': (allocation, payload) => {
    return axios.put(`adhoc-allocation/${allocation}/restore`, payload);
  }, 'destroy': (allocation, payload?: any) => {
    return axios.delete(`adhoc-allocation/${allocation}/destroy`, {data: payload});
  }
}
export const ConsultantServiceTypeController = {
  'index': (params?: any) => {
    return axios.get(`consultant-service-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`consultant-service-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`consultant-service-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`consultant-service-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`consultant-service-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`consultant-service-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`consultant-service-type/${type}/destroy`, {data: payload});
  }
}
export const ClientContactController = {
  'index': (client, params?: any) => {
    return axios.get(`client/${client}/contact`, {params});
  }, 'store': (client, payload) => {
    return axios.post(`client/${client}/contact`, payload);
  }, 'update': (client, contact, payload) => {
    return axios.put(`client/${client}/contact/${contact}`, payload);
  }, 'destroy': (client, contact, payload?: any) => {
    return axios.delete(`client/${client}/contact/${contact}`, {data: payload});
  }
}
export const ApplicationsMemberTypeController = {
  'index': (params?: any) => {
    return axios.get(`applications-member-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`applications-member-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`applications-member-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`applications-member-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`applications-member-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`applications-member-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`applications-member-type/${type}/destroy`, {data: payload});
  }
}
export const ConsultantContactTypeController = {
  'index': (params?: any) => {
    return axios.get(`consultant-contact-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`consultant-contact-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`consultant-contact-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`consultant-contact-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`consultant-contact-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`consultant-contact-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`consultant-contact-type/${type}/destroy`, {data: payload});
  }
}
export const SettingController = {
  'index': (params?: any) => {
    return axios.get(`setting`, {params});
  }, 'store': (payload) => {
    return axios.post(`setting`, payload);
  }, 'show': (setting, params?: any) => {
    return axios.get(`setting/${setting}`, {params});
  }, 'update': (setting, payload) => {
    return axios.put(`setting/${setting}`, payload);
  }, 'haul': (setting, payload) => {
    return axios.patch(`setting/${setting}`, payload);
  }, 'archive': (setting, payload?: any) => {
    return axios.delete(`setting/${setting}`, {data: payload});
  }, 'restore': (setting, payload) => {
    return axios.put(`setting/${setting}/restore`, payload);
  }, 'destroy': (setting, payload?: any) => {
    return axios.delete(`setting/${setting}/destroy`, {data: payload});
  }, 'showApplication': (params?: any) => {
    return axios.get(`setting/application`, {params});
  }, 'showSystem': (params?: any) => {
    return axios.get(`setting/system`, {params});
  }
}
export const CorrespondenceTypeController = {
  'index': (params?: any) => {
    return axios.get(`correspondence-type`, {params});
  }, 'store': (payload) => {
    return axios.post(`correspondence-type`, payload);
  }, 'show': (type, params?: any) => {
    return axios.get(`correspondence-type/${type}`, {params});
  }, 'update': (type, payload) => {
    return axios.put(`correspondence-type/${type}`, payload);
  }, 'archive': (type, payload?: any) => {
    return axios.delete(`correspondence-type/${type}`, {data: payload});
  }, 'restore': (type, payload) => {
    return axios.put(`correspondence-type/${type}/restore`, payload);
  }, 'destroy': (type, payload?: any) => {
    return axios.delete(`correspondence-type/${type}/destroy`, {data: payload});
  }
}
export const ApplicationsMemberController = {
  'index': (application, params?: any) => {
    return axios.get(`applications-member/${application}`, {params});
  }, 'store': (application, member, payload) => {
    return axios.post(`applications-member/${application}/${member}`, payload);
  }, 'update': (applicationId, memberId, payload) => {
    return axios.put(`applications-member/${applicationId}/${memberId}`, payload);
  }, 'destroy': (application, member, payload?: any) => {
    return axios.delete(`applications-member/${application}/${member}`, {data: payload});
  }
}

export enum ActionEnum { 'Created' = 1, 'Updated' = 2, 'Archived' = 3, 'Restored' = 4, 'Destroyed' = 5 }
